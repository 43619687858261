.ReplyForumBody{
 
        width: 100%;
        height: calc(98vh - 4rem);
      
     
}
.btn-reply-s11 {
        background: #0072BB;
        border: none!important;
        border-radius: 30px;
        color: #fff!important;
        font-size: 16px;
        padding: 5px 20px;
    }
    .d-f-reply {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }