.VlogPlayerBody {
  width: 100%;
  height: calc(98vh - 4rem);
}

.reactplayerVlog {
  border: 0;
  height: "500px";
  max-width: "100%" !important;
  border-radius: "15px" !important;
}


.react-player {
border-radius: 15px;
  }


  .react-player__preview{
    border-radius: 15px;
  }