/* .ag-format-container {
    width: 1142px;
    margin: 0 auto;
  } */
  
  

  .ag-courses_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  
    padding: 50px 0;
  }
  .ag-courses_item {
      /* flex-basis: calc(33.33333% - 30px); */
    -ms-flex-preferred-size: calc(33.33333% - 30px);
    flex: 0 1 calc(25% - 30px);
    margin: 0 15px 30px;
  

    
  }

  
  @media only screen and (max-width: 1400px) {
    .ag-courses_item {
      -ms-flex-preferred-size: calc(330% - 30px);
      flex-basis: calc(33% - 30px);
    }

  }
  
  @media only screen and (max-width: 767px) {
    .ag-format-container {
      width: 96%;
    }
  
  }
  @media only screen and (max-width: 500px) {
    .ag-courses_item {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
    }
    .ag-courses-item_title {
      min-height: 72px;
      line-height: 1;
  
      font-size: 24px;
    }
    .ag-courses-item_link {
      padding: 22px 40px;
    }
    .ag-courses-item_date-box {
      font-size: 16px;
    }
  }
  .VlogBody{
    width: 100%;
    height: calc(98vh - 4.0rem);
  
}
.vlog-thum>img{
    width: 100%;
    border-radius: 12px;

    }

    .vlog-details {
        display: flex;
        gap: 5px;
        align-items: flex-start;
        padding: 10px 0px;
    }
.vlog-details>img{
    width: 40px;
    border-radius: 50px;
    border: 1px solid;
}
.content-vlog-details{

}
.content-vlog-details>h1{


    font-size: 13px;

    text-transform: uppercase;
    font-weight: 600;
    color: black;

    font-family: 'Montserrat', sans-serif;

}
.content-vlog-details>h2{
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;

    }
    .vlog-card-section {
        flex-wrap: wrap;
        justify-content: space-evenly;
        display: flex;
        padding: 20px 0px;
        gap: 5px;
    }

@media (max-width:550px) {
    .vlog-card-section {
        flex-wrap: wrap;
  
        padding: 20px 0px;
    }
}
.vlogs-heading{
font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 0px;
}
@media (max-width:1358px) {

 
}
@media (max-width:550px) {

    .vlog-card {
        width: 450px;
    }

}


.vlogs-card-s1{

}


@media (max-width:900px) {
    
    .ag-courses_item {
        -ms-flex-preferred-size: calc(330% - 30px);
        flex-basis: calc(50% - 30px);
      }
  
}


@media (max-width:550px) {
    
    .ag-courses_item {
        -ms-flex-preferred-size: calc(330% - 30px);
        flex-basis:  unset;
      }
  
}


.vlog-card {
cursor: pointer;
}
