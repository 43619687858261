.CourseBodyMain{
    width: 100%;
    height: calc(98vh - 4.0rem);
    flex: 1;
  
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}


@media (max-width:767px) {
  .header__search{
    display: none;
  }
}


.CourseMainCard{
    
    width: 350px;
    height: 200px;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}


.CourseMainCardImg{
    width: 300px;
    height: 170px;
    border-radius: 15px;
    object-fit: contain;

}

.CourseMainCardBody{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.CourseMainCardBody h1{
font-size: 13px;
padding-top: 10px;
text-transform: uppercase;
font-weight: 600;
color: black;
text-align: center;
font-family: 'Montserrat', sans-serif;

}



.react-tabs__tab-list{
border-bottom: 0px !important;
}
.react-tabs__tab--selected{
  border: none !important;
}
/* CSS */
.button-10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  border-radius: 6px;
  border: none;

  color: #fff;
  background: #0072BB;
   background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-10:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}