.reply-bg{
    /* background-image: url("../../Img/20210204_a547.jpg"); */
    background-position: center;
    background-size: cover;
    /* min-height: 618px; */
}
.btn-side{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}
.record-mic{
    cursor: pointer;
}
.border-reply{

    border: 1px solid #b8acac;
    width: 90%;
    border-radius: 5px;
}
.reply-main-data{
    padding: 50px 0px 30px 0px;
    display: flex;
    margin-bottom: 151px;
    justify-content: center;
    /* flex-direction: column; */
    /* align-items: center; */
    /* padding: 7% 4%; */
}
.side-reply{
    display: flex;
    width: 100%;
}

.data-reply{
    display: flex;
    
    width: 100%;

}

.reply-data-s{
    width: 100%;
}
.side-r-btns{
    padding: 4% 4%;
    display: flex;
    justify-content: center;
width: 10%;
}


.forumimgimg{
width: 200px;
height: 200px;
border-radius: 5px;
cursor: pointer;
}

.btn-reply-s{

    padding: 11px 40px;
 
    color: white;
    background: #0072BB;
    border-radius: 30px;
    border: none;
}
.btn-reply-1{

    padding: 11px 40px;
 
    color:black;
    background:white;
    border-radius: 30px;
    border: none;
}
.btn-reply-1:hover{
    color:white;
    background:#0072BB;
}

.btn-reply-2{

    padding: 11px 40px;
 
    color:black;
    background:white;
    border-radius: 30px;
    border: none;
}
.btn-reply-2:hover{
    background:#0072BB;

    color:white;
}
@media only screen and (max-width: 850px) {
.data-reply {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.side-r-btns {

    width: auto;
}
.reply-data-s {
    width: 100%;
}
}
.head-reply-comment{

    padding: 10px 20px;

}
.btn-head-comm{

    padding: 11px 17px;
    color: black;
    background: white;
    border-radius: 30px;
    /* border: none; */
    border: 1px solid;
}
.btn-head-comm:hover{
    background:#0072BB;
    color:white;
}
.reply-h1{
    color: #444444;
    text-transform: capitalize;
    font-size: 21px;
    width: fit-content;
    padding: 15px 0px ;
}

.span-2-reply{
    color: #a3a5a9;
    padding: 10px 0px;
    font-size: 14px;
}
.name-hov{
    color: #444444;
    cursor: pointer;;

}
.name-hov:hover{
    color: #0072BB;

}
.para-1-reply{
    color: #444444;
    padding: 10px 0px;
    text-transform: capitalize;
}
.para-1-reply>p{
    white-space: pre-wrap;
    text-transform: capitalize;
line-height: 1.5;
}
.reply-count>h1{
    color: #444444;
    padding: 10px 0px;

    font-size: 15px;
}
.reply-count{
    padding: 10px 20px;
}
.user-reply-dp{

    width: 52px;
    border-radius: 50px;
}
.another-reply-1{

    padding: 10px 20px;
}
.user-dp-time-member{

    display: flex;
    gap: 1rem;
}
.user-data-t>h1{
    color: #444444;
    font-size: 20px;
}
.user-data-t>p{
    color: #444444;
    font-size: 14px;
}
.user-data-t{

    display: flex;
    flex-direction: column;
    gap: 3px;
    
}
.section-reply-p2{

    font-size: 15px;
    margin-top: 12px;
}

@media only screen and (max-width: 870px) {


.data-reply {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
}

.reply-btn-count{

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.reply-h1-y{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;

}
.reply-h2-y{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    color: #a3a5a9;
 
}
.section1-reply{
    display: flex;
    /* width: 100%; */
    align-items: center;
    padding: 13px 23px; 
    /* flex-wrap: wrap; */
    gap: 5px;
    justify-content: space-between;
}
.reply-headings{
    line-height: 2;
}
.section-2-reply{
    padding: 20px;
}
.section-2-replytext{
    
    box-sizing: border-box;
width: 100%;
margin: 0;
height: 100px;
padding: 0 calc(16px / 2);
font-family: inherit;
font-size: 16px;
line-height: calc(16px + 16px / 2);
color: #888;
background-color: none;
border: 0;
outline: 0 !important;
resize: none;
overflow: hidden;
}
.upload-options2{
    display: flex;
    gap: 1rem;
}

.section-2-reply-1{
    padding: 20px;
}
.check-box-post-btn-s{
 
    padding: 20px;
    display: flex;
    justify-content: space-between;
}
.post-btn-dis1{
    padding: 7px 16px;
    border: 1px solid;
    color: white;
    background: #0072BB;
    border-radius: 15px;
}
.ReactModal__Overlay--after-open{
    z-index: 100;
    background-color: rgb(0 0 0 / 75%) !important;
}
.reply-para-uu{

text-transform: capitalize;
    line-height: 1.5;
    color: #444444;
}
.wrp{
    white-space: pre-wrap;
    text-transform: capitalize;
}
/* audio{
    position: absolute;
    top: 325px;
    right: 397px;

} */
.audio-record2{
    display: flex;
}
.drop-d1 {
    display: flex;
    gap: 30px;
}
.audio-r1 {
    display: flex;
    align-items: center;
    gap: 10px;
    
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
padding: 5px 10px;
border-radius: 15px;
background: white;

}
.btn-rec-fun{
    background-color: transparent;
}
.audio-r1>h1 {
    font-size: 10px;
    white-space: pre;
    margin: 0;
}
.audio-record2 {
    display: flex;
    gap: 10px;
    padding: 10px 15px;
    width: fit-content;
    background-color: white;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    transform: translate(-12px, 0px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 1;
    align-items: center;
}
.audio-record2>svg{
    cursor: pointer;


}
.audio-r1>svg{
    cursor: pointer;
}


@media only screen and (max-width: 700px) {
    .btn-side {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }

}
@media only screen and (max-width: 500px) {

    .audio-record2 {
        padding: 10px !important;
    }
    .audio-s{
        width: 250px;
    }
}
.audio-section-icons{
    display: flex;
    gap: 5px;
}
.audio-section-icons>svg{
    cursor: pointer;


}

@media only screen and (max-width: 400px) {
    .audio-record2 {
        display: flex;
        gap: 10px;
        padding: 10px 15px;
        width: -moz-fit-content;
        width: fit-content;
        background-color: white;
        border-radius: 40px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        transform: translate(-12px, 0px);
        transition: opacity 0.3s ease, transform 0.3s ease;
        z-index: 1;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

}


