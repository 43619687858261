.ModuleBody{
    width: 100%;
    height: calc(98vh - 4.0rem);
    /* flex: 1; */
/*     
    justify-content: center;
    align-items: center;
    display: flex; */
    cursor: pointer;
}


.ModuleBodyTopPanel{
    background-image: url('../../assets/images/logo/Artboard2.jpg');
    width: 100%;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    height: 300px;
}

.ModuleBodyMidBody{
    width: 100%;
    display: flex;
    padding: 30px 0px;
    height: 100%;
    /* align-items: center; */

}

.ModuleBodyMidBodyh1{
    font-size: 20px;
    padding-top: 15px;
font-family: 'Montserrat', sans-serif;
font-weight: 400;
}


.ModuleBodyMidBodyLeft{
width: 70%;


}

.ModuleBodyMidBodyRight{
width: 30%;
justify-content: center;
align-items: center;
height: 100%;
display: flex;

}


.ModuleLessonCard{

    width: 100%;
    /* height: 75px; */
    background-color: #fff;
    display: flex;
    border-radius: 5px;
    margin-top: 15px;
    border: 1px solid transparent;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}

.ModalInfoCardMiddleh12{
    font-size: 13px;
    padding-top: 10px;
    text-transform: uppercase;
    font-weight: 800;
    color: black;
 
    font-family: 'Montserrat', sans-serif;
}

.ModuleLessonCard:last-child{
    margin-bottom: 50px;
}

.ModuleLessonCard:hover{
    border: 1px solid black;
    transition: .3s linear;
}


.ModuleLessonCardLeft{
    height: 80px;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    border-right: 2px solid black;
}

.ModuleLessonCardRight{
    width: 80%;
    /* background-color: blue; */
    display: flex;
    justify-content: flex-start;
    padding: 10px 30px;
    flex-direction: column;
    align-items: flex-start;
}

.ModuleLessonCardRight2{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ModuleLessonCardLeft h1{
font-size: 2rem;
color: rgba(0, 0, 0, 0.719);
margin-bottom: 0px !important;
font-family: 'Montserrat', sans-serif;
font-weight: 400;



}

.ModuleLessonCardRight h1{
font-size: 1.5rem;
font-family: 'Montserrat', sans-serif;
margin-bottom: 0px !important;

font-weight: 600;
text-transform: uppercase;


}

.ModuleLessonCardRight h5{
    font-size: 19px;
    padding-top: 3px;
    font-weight: 400;
font-family: 'Montserrat', sans-serif;

}


.ModalInfoCardMiddle{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
}

.percentage-course-com{
    font-size: 13px;
    padding-top: 10px;
    text-transform: uppercase;
    font-weight: 600;
    color: black;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    
    }

@media (max-width: 1200px) {
    
.ModuleBodyMidBodyLeft{
    width: 100%;
    
    
    }
    
    .ModuleBodyMidBodyRight{
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
    margin-top: 25px;
    
    }

    
.ModuleBodyMidBody{
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: column-reverse;
}
    
}


@media (max-width: 1226px) {
    .ModuleLessonCardRight h1{
        font-size: 1.2rem !important;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 0px !important;
        
        font-weight: 600;
        text-transform: uppercase;
        
        
        }
        
        .ModuleLessonCardRight h5{
            font-size: 14px !important;
            padding-top: 3px;
            font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        
        }

        .ModuleLessonCardLeft h1{
            font-size: 1.5rem !important;
            color: rgba(0, 0, 0, 0.719);
            margin-bottom: 0px !important;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            
            
            
            }
}

@media (max-width: 964px) {

    .ModuleLessonCardLeft{
        height: 70px;
        width: 15% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: red; */
        border-right: 2px solid black;
    }
    
    .ModuleLessonCardRight{
        width: 75% !important;
        /* background-color: blue; */
        display: flex;
        justify-content: flex-start;
        padding: 10px 30px;
        flex-direction: column;
        align-items: flex-start;
    }

    .ModuleLessonCardRight h1{
        font-size: 1rem !important;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 0px !important;
        
        font-weight: 600;
        text-transform: uppercase;
        
        
        }

        .ModuleLessonCardRight{
            justify-content: center !important;
        }
        
        .ModuleLessonCardRight h5{
            font-size: 14px !important;
            padding-top: 3px;
            font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        
        }

        .ModuleLessonCardLeft h1{
            font-size: 1.1rem !important;
            color: rgba(0, 0, 0, 0.719);
            margin-bottom: 0px !important;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            
            
            
            }
}


.ModuleInfoCard{
    width: 300px;
    height: 480px;
   /* border: 2px solid black; */
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ModuleInfoCardTop{
    height: 200px;
    width: 100%;
    background-image: url('../../assets//images//logo/Artboard2.jpg');
background-position: center;
background-size: cover;
overflow: hidden;
border-radius: 15px 15px 0px 0px;
}


.ModuleInfoFottoerSection1{
    display: flex;
    width: 250px;
    /* width: 100%; */
    justify-content: center;
    align-items: flex-start;
    /* gap: 60px; */
    flex-direction: column;
}

.ModuleInfoFottoerSection1 h5{
font-weight: 600;
font-size: 18px;
margin-top: 5px;
}


.ModuleInfoFottoerSection1h1{
    font-weight: 400 !important;
}


.ModuleInfoFottoerSection2{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

/* CSS */
.button-7 {
  background-color: #0072BB;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px 1.5em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.button-7:hover,
.button-7:focus {
  background-color:#0072BB;
}

.button-7:focus {
  box-shadow:#0072BB70;
}

.button-7:active {
    box-shadow:#0072BB70;

  box-shadow: none;
}

.modules-name-s1{
width: 100%;
}

.modules-name-s2{
    display: flex;
}

.mod-d1>h1{
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.719);
    margin-bottom: 0px !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
.mod-d2> h1 {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0px !important;
    font-weight: 600;
    text-transform: uppercase;
}

.mod-d2>h5 {
    font-size: 15px;
    padding-top: 3px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.mod-d1 {

    height: 50px;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;

    border-right: 2px solid black;

}

.mod-d2 {
    width: 80%;
    /* background-color: blue; */
    display: flex;
    justify-content: flex-start;
    padding: 10px 30px 0px 30px;
    flex-direction: column;
    align-items: flex-start;

}
.modules-name-s0{


    width: 100%;
        display: flex;
}


.mod-hr1{
    margin: 0;
}

.chap-mod-n1{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.chap-mod-n1>p{
font-size: 14px;
margin: 0;
}
.chap-mod-n1>span{
    font-size: 12px;
    margin: 0;
    }


    .mod-dd1 {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        margin-top: 10px;
        border-radius: 5px;
    }
  
    .mod-dd5{
    display: flex;
    gap: 10px;
    }

.mod-dd3 {
    display: flex;
    padding: 10px;
    background: #F3F4F8;
    border-radius: 5px;
    justify-content: space-between;

}

    .mod-dd5>h1{
        font-size: 16px;
        font-weight: 400;
    }

.mod-dd6:hover{
    background-color: rgba(211, 211, 211, 0.199);
}

    .mod-dd6>p{

        margin: 0;
 font-size: 15px;
 font-size: 15px;
}

.mod-dd6 {
    border-top: 1px solid lightgray;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
}

    .mod-dd7>p{

        margin: 0;
 font-size: 15px;

    }

    .mod-dd7{
display: flex;
gap: 10px;
    }

    .mod-dd7>span{

        background-color: #0072BB;
    padding: 3px 10px;
    border-radius: 5px;
    color: white;
    font-size: 12px;

    }

 


    @media (max-width:500px) {
        .mod-dd5{
            flex-wrap: wrap;
        }
        .mod-dd6 {
        
            justify-content: space-between;
            flex-direction: column;
        }
        .mod-dd7 {
        
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .mod-dd5>h1 {
            font-size: 13px;
            font-weight: 400;
        }
    }