.VideoLecture {
  width: 100%;
  height: calc(98vh - 4rem);
  display: flex;
  justify-content: center;
  align-items: flex-start;
 
}


.videonotavailableh1{
  font-weight: 400;
font-family: "Montserrat", sans-serif;
font-size: 18px;
}


.iframevideoplayer {
  border: 0;
  width: 100%;
  height: 100%;
  /* margin-top: 40px; */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.VideoPlayer{
    width: 720px;
  height: 405px;
}

@media (max-width:823px) {
    .VideoPlayer {
        border: 0;
        width: 600px;
        height: 350px;
        margin-top: 40px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      } 
    
}

@media (max-width:606px) {
    .VideoPlayer {
        border: 0;
        width: 100%;
      
        height: 350px;
        margin-top: 40px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      } 
    
}



.section-bgggg{
    background-color: white !important;
}


.headingchaptername{
font-size: 30px;
margin-bottom: 0 !important;
font-weight: 400;
font-family: "Montserrat", sans-serif;
text-transform: uppercase;
}


.videoheadingmargibn{
    margin-bottom: 0px !important;
}

.dropdown {
    display: inline-block;
    position: relative;
    z-index: 99;
  }
  
  .dd-button {
    display: inline-block;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px 30px 10px 20px;
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .dd-button:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
  }
  
  .dd-button:hover {
    background-color: #eeeeee;
  }
  
  
  .dd-input {
    display: none;
  }
  
  .dd-menu {
    position: absolute;
    top: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
    background-color: #ffffff;
    list-style-type: none;
  }
  
  .dd-input + .dd-menu {
    display: none;
  } 
  
  .dd-input:checked + .dd-menu {
    display: block;
  } 
  
  .dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .dd-menu li:hover {
    background-color: #f6f6f6;
  }
  
  .dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }
  
  .dd-menu li.divider{
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }


  .dropdownheading{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .heifhtsetcsabody{
    height: fit-content !important;
  }

  .float{
    position:fixed;
    width:150px;
    height:60px;
    bottom:40px;
    display: flex;
    justify-content: center;
    align-items: center;
    right:40px;
    z-index: 100;
    background-color:#0C9;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    box-shadow: 2px 2px 3px #999;
    cursor: pointer;
  }
  
  .my-float{
    font-size: 14px;
  }