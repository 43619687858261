.ChapterBody {
  width: 100%;
  height: calc(98vh - 4rem);
  overflow: hidden scroll;
}

.ChapterBody::-webkit-scrollbar {
  display: none;
}

.ChapterBodyTop {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 50px;
  width: 100%;
  height: 200px;
  background-color: #0072bb;
}

.ChapterBodyToph1 {
  font-size: 30px;
  color: white;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.ChapterBodyToph2 {
  font-size: 25px;
  color: white;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.TabButton {
  background-color: white;
  cursor: pointer;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.TabButtonClick:hover {
  transition: 0.3s ease;
  background-color: rgba(255, 255, 255, 0.445);
}

.TabButtonClick {
  background-color: #0072bb;
  cursor: pointer;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ChapterTabsMain {
  list-style: none;
  line-height: normal !important;
  width: 100%;
  height: fit-content;
  padding-left: 0px !important;
  flex-wrap: wrap;
  background-color: #0072bb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TabButtonp1 {
  font-size: 13px;
  margin-bottom: 0px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.TabButtonp11 {
  font-size: 13px;
  margin-bottom: 0px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  opacity: 0;
}

.TabButtonp2 {
  margin-bottom: 0px !important;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 800;
}

.TabButtonp22 {
  margin-bottom: 0px !important;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 400;
  color: lightgray;
}
.TabButtonp3 {
  margin-bottom: 0px !important;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 900;
  margin-top: -10px;
}

.TabButtonp33 {
  margin-bottom: 0px !important;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  /* font-weight: 900; */
  font-weight: 400;
  color: lightgray;
  margin-top: -10px;
}

.VideoSlide1 {
  transition: 0.3s ease-in-out;
  height: 250px;
  width: 300px;
  /* background-color: grey; */
}

.VideoSlideTop h1 {
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}

.VideoSlideMiddle {
  height: 200px;
  width: 300px;
  background-color: #393939;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 4px;
}

.VideoSldieTopCard {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullets {
  display: none;
}

.VideoSlide2 {
  height: 250px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.VideoSlideTop2 h1 {
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  display: none;
}

.VideoSlideMiddle2 {
  height: 150px;
  width: 250px;
  background-color: #393939;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Playbutton {
  width: fit-content;
  height: fit-content;
  border-radius: 50px;

  background-color: #0072bb;
}

.sliderbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.sliderbarspan {
  width: 80%;
  height: 4px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.678);
}

.sliderbarspan1 {
  font-size: 13px;
  width: 10%;
  color: rgba(255, 255, 255, 0.678);
}

.Playbutton1 {
  width: fit-content;
  height: fit-content;
  border-radius: 50px;

  background-color: #0072bb;
}

.sliderbar1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.sliderbarspan11 {
  width: 70%;
  height: 4px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.678);
}

.sliderbarspan112 {
  width: 10%;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.678);
}

.VideoSldieTopCard1 {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderbar112 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderbar112 h1 {
  width: 85%;
  font-size: 10px;
  white-space: nowrap;
  text-transform: capitalize;
  color: white;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0px !important;
}

.swiper-slide-active {
  z-index: 100;
}

.mySwiper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  /* width: 1000px; */
}

.swiperslide1111 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.ChapterBodyBottom {
  width: 100%;
  height: 500px;
  /* margin-bottom: 200px; */
  /* background-color: grey; */
}

.ChapterBodyFotter {
  width: 100%;
  height: 200px;
}

.ChapterBodyBottomBackground {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.ChapterBodyBottomBackgroundLeft {
  width: 20%;
  height: 400px;
  position: absolute;
  left: 0;
}

.ChapterBodyBottomBackgroundRight {
  width: 80%;
  background-color: #e4e4e4;
  height: 400px;
  position: absolute;
  right: 0;
}

.ChapterBodyBottomBody {
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
}

.QUestionsCardsEction {
  position: absolute;
  left: 15%;
  display: flex;
  align-items: center;
}

.AddQuestionCard {
  width: 200px;
  height: 300px;
  background-color: white;
  margin-right: 60px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;
}

.QuestionCard1 {
  width: 300px;
  height: 320px;
  background-color: white;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;
  margin-right: 50px;
}

.QuestionCardTop {
  height: 60px;
  width: 100%;
  /* background-color: red; */
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.QUestionCard1MiddleBodyTop {
  height: 30px;
  width: 100%;
  /* background-color: red; */
  padding: 0px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
}

.whitecolorh1text {
  color: white;
}

.whitecolorh1texttime {
  color: rgba(255, 255, 255, 0.767) !important;
}

.whitecolorseth1size{
  font-size: 10px !important;
}

.QUestionCard1MiddleBodyMiddle {
  padding: 10px;
  /* width: 100%; */
  min-width: 249px !important;
  height: 120px;

  overflow: hidden;
}

.react-tabs__tab--selected{
background-color: none !important;
}

.react-tabs__tab{
  padding: 0px !important;
  bottom: 0px !important;
}

.QUestionCard1MiddleBodyMiddle p {
  color: white;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  line-height: normal;
  margin-bottom: 0px;
  width: 100%;
  height: 120px;
  overflow: hidden;
}

.QuestionCardTopCircle {
  height: 40px;
  width: 40px;
  background-color: #0072bb;
  border-radius: 25px;
}

.QuestionCardTopText h1 {
  font-size: 13px;
  margin-bottom: 0px !important;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.QuestionCardMiddle {
  width: 100%;
  height: 200px;
  /* background-color: #0072bb; */
}

.QuestionCardMiddleButton {
  padding: 0px 15px;
  margin-top: 20px;
}
.QuestionCardMiddleButton button {
  background-color: #0073bb59;
  color: #0072bb;
  font-size: 12px;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.QuestionCardMiddleButton button:hover {
  background-color: #0073bb9d;
  color: #0072bb;
  font-size: 12px;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.QuestionCardMiddleUL {
  color: #0073bb59;
  margin-top: 15px;
  row-gap: 10px;
  display: flex;
  flex-direction: column;
}

.QuestionCardMiddleLI span {
  color: grey;
  font-size: 10px;

  font-weight: 500;
  line-height: normal !important;
  font-family: "Montserrat", sans-serif;
}

.QuestionCard1Top {
  height: 60px;
  width: 100%;
  /* background-color: red; */
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.QuestionCardTopText h1 {
  white-space: nowrap;
  text-transform: capitalize;
}

.textquestion {
  width: 55%;
  white-space: nowrap;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
}

.QuestionCardTopTime h1 {
  font-size: 12px;
  margin-bottom: 0 !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: grey;
  margin-left: 10px;
}

.QuestionCard1Middle {
  width: 100%;
  height: 55px;

  overflow: hidden;
}

.QuestionCard1Middle h1 {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  padding: 0 15px;
}

.QuestionCard1Middle2Body2{
  padding: 0px 15px;
  cursor: pointer;
}

.QuestionCard1Middle2Body2 span{
 color: grey;
 font-size: 13px;
 margin-left: 2px;
 font-family: 'Courier New', Courier, monospace;
}


@media (max-width:844px) {
  .QuestionCardSection{
    display: none !important;
  }

  .QuestionCardSection3{
    display: none !important;
  }

  .ChapterBodyBottomBackgroundRight {
    width: 40% !important;
    background-color: #e4e4e4;
    height: 400px;
    position: absolute;
    right: 0;
}

.QUestionsCardsEction {
  position: absolute;
  left: 45% !important;
  display: flex;
  align-items: center;
}

.QuestionCardSection2{
  display: flex !important;
}

.ChapterBodyBottomBody {
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

  
}

.QuestionCard1Middle2 {
  width: 110%;

  height: 160px;
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;

}

.QuestionCardSection {
  display: flex;
  width: 65vw;
  overflow-y: auto;
  /* justify-content: center; */
  align-items: center;
  scroll-behavior: smooth;

}

.QuestionCardSection3 {
  display: flex;
  width: 45vw;
  overflow-y: auto;
  /* justify-content: center; */
  align-items: center;
  scroll-behavior: smooth;

}

@media (max-width: 1026px) {
  .QuestionCardSection3 {

    width: 40vw !important;
   
  
  }
}

@media (max-width: 990px) {
  .QuestionCardSection3 {

    width: 33vw !important;
   
  
  }
}


@media (max-width: 1550px) {
  .QuestionCardSection {

    width: 53vw !important;
   
  
  }
}


@media (max-width: 1492px) {
  .QuestionCardSection {

    width: 50vw !important;
   
  
  }
}

@media (max-width: 1200px) {
  .QuestionCardSection {

    width: 43vw !important;
   
  
  }
}

@media (max-width: 991px) {
  .QuestionCardSection {

    width: 33vw !important;
   
  
  }
}

.QuestionCardSection2 {
  display: none;
  width: 100%;
  overflow-y: auto;
  /* justify-content: center; */
  padding: 30px;
  border-radius: 5px 0 0 5px;
  background-color: #e4e4e4;
  align-items: center;
  scroll-behavior: smooth;

}


.QuestionCardSection::-webkit-scrollbar{
  display: hidden;
}

.QuestionCard1Middle2Body {
  width: 90%;
  height: 100%;
  border-radius: 0 0 4px 4px;
  background-color: #0072bb;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}



.custom-loader {
  width:50px;
  height:50px;
  border-radius:50%;
  border:8px solid;
  border-color:#2B2B2C;
  border-right-color: #0072bb;
  animation:s2 1s infinite linear;
}
@keyframes s2 {to{transform: rotate(1turn)}}


.SpinnerwidthBox{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}